import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
// import Rating from 'common/components/Rating';
import GlideCarousel from 'common/components/GlideCarousel';
import GlideSlide from 'common/components/GlideCarousel/glideSlide';
import { SectionHeader } from '../appModern.style';
import SectionWrapper, { CarouselWrapper } from './testimonial.style';
import Image1 from '../../../common/assets/image/appModern/Slider1.png'
import Image2 from '../../../common/assets/image/appModern/Slider2.png'
import Image3 from '../../../common/assets/image/appModern/Slider3.png'
import Image4 from '../../../common/assets/image/appModern/Slider4.png'
import Image5 from '../../../common/assets/image/appModern/Slider5.png'
import Image6 from '../../../common/assets/image/appModern/Slider6.png'
import Image7 from '../../../common/assets/image/appModern/Slider7.png'
import Image8 from '../../../common/assets/image/appModern/Slider8.png'
import Image9 from '../../../common/assets/image/appModern/Slider9.png'
import Image10 from '../../../common/assets/image/appModern/Slider10.png'
import Image11 from '../../../common/assets/image/appModern/Slider11.png'
import Image12 from '../../../common/assets/image/appModern/Slider12.png'
const Testimonial = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        testimonial {
          slogan
          title
          reviews {
            id
            title
            description
            avatar
            name
            designation
            review
          }
        }
      }
    }
  `);
  const { slogan, title} = data.appModernJson.testimonial;

  const Pics = [Image1, Image2, Image3,Image4, Image5, Image6,Image7, Image8, Image9,Image10, Image11, Image12]
  const glideOptions = {
    type: 'carousel',
    gap: 0,
    autoplay: 5000,
    perView: 2,
    animationDuration: 700,
    breakpoints: {
      991: {
        perView: 1,
      },
    },
  };

  return (
    <SectionWrapper id="testimonial">
      <Container>
        <SectionHeader>
          <Fade up>
            <Heading as="h5" content={slogan} />
            <Text content={title} />
          </Fade>
        </SectionHeader>

        <CarouselWrapper>
          <Fade up delay={100}>
            <GlideCarousel
              options={glideOptions}
              nextButton={
                <Button
                  icon={<i className="flaticon-next" />}
                  aria-label="Next"
                  variant="fab"
                />
              }
              prevButton={
                <Button
                  icon={<i className="flaticon-left-arrow" />}
                  aria-label="Prev"
                  variant="fab"
                />
              }
            >
              <Fragment>
                {/* {reviews.map((item) => (
                  <GlideSlide key={`testimonial--key${item.id}`}>
                    <div className="review-card">
                      <Heading as="h3" content={item.title} />
                      <Text content={item.description} />
                      <div className="card-footer">
                        <div className="image">
                          <Image src={item.avatar} alt="Client Image" />
                        </div>
                        <div className="reviewer-info">
                          <div className="content">
                            <Heading as="h4" content={item.name} />
                            <Text content={item.designation} />
                          </div>
                          <Rating rating={item.review} />
                        </div>
                      </div>
                    </div>
                  </GlideSlide>
                ))} */}
{Pics.map((pic,i )=> (
<GlideSlide>
<Image key={i} src={pic} alt="Ofice Images" />
</GlideSlide>
))}

              </Fragment>
            </GlideCarousel>
          </Fade>
        </CarouselWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Testimonial;
